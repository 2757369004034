import React, { createElement } from 'react';


const easternCape = props => <path {...props} d="M327 227l2 3-2 2-4 4c-2-1-4 0-3 3v2l2 1c0 1 3 3 3 1 1-2 3-1 3 1s7 2 9 2l8 2c2-1 3 2 5 2 1 3 6 3 5 6l2 3-3 4c-2 2-4 6-7 7s-3 3-5 5l-7 5-9 12c-7 7-13 16-22 19l-2 3-3 3-6 5c-4 5-11 6-15 11-3 3-7 3-11 5s-6 4-11 3-8-3-13-1l-5 5c-1 3 1 2 2 5l-12-2c-3-1-6-1-8 1l-1 3c-1 1-2 2 0 3-2 1-10-1-12-2-5-4-14-4-20-5-1-1 0-2 1-3l-2-1-2-2h-4l4-4h3c1-2-1-2-2-3-1-2 1-2-2-3l-3-1c-3 0-4-2-8-1h-7c-5 0 3-8 4-10 0-2-1-3 1-5 1-2 4-1 2-4l1 2c2-3 5-2 7-1 0-1-1-4-3-5 2-1-1-5-1-7l2-2c1 0 2 1 3-1 1 0 7 1 6-1-1-1 1-2 2-3h2c1 0 1 2 2 1l4-2c3 0 2-2 1-3h1v-1c2-1 4-5 0-4l1-2v-1c-2-4 2 0 4-2s5 1 5-3l2-7 7-1c0-2 8-5 10-5l3 1c2-1 0-2 1-3s3-1 2-2c3-2 0-6 2-9-3 1-2-3-2-5h4l1 2c2 0 3 0 5-2l1-2h2c2-2 3 0 4 0s0-2 2-2l3 3c2 0 4-2 4 1 1 2 4 1 4 2h6c2 1 2-3 5-2-3-3 2-1 2-3l5-1v-1h1v-1c2-1 1-1 0-2l3-1c1 3 5 5 6 7 2 2 5 1 6 2 1 2 4 1 5 2l3-8c3-1-1-2 0-4l3-2c1-1 1-2 3-2h4c3 1 8-3 11-4z"/>

const freeState = props => <path {...props} d="M288 132h6c0-1 4-3 6-2l3 6 1-1 3 1c2 0 4 0 4 2 1-1 2 0 3 1v1l3-1v-1h-1c2-1 4 2 6 2l2-1h3l1 3c1 2 1 0 3 1 2 0 3 1 4 3 3-2 4 3 5 5 3 4 3 0 6 1h-2c1 2 3 3 1 5-2 1-2 3-1 5 1 1-2 4-2 6s2 3 0 5l-5 5-6 3c-1 2 0 3-2 3-4-1-4 2-6 4-2 3 0 1-4 0l-1-3h-3c-1 0-6 0-5 3h-5l-3 3-1 2h-2-1l-1 1h-3l-1 3h-1l-1 1 1 1h-1c0 2-3 7-5 7l-1 2c1 3-7 6-9 7v1l-1-1 4 9c1 2 3 8 6 7-1 2-2 4 0 5h-2c1 2 2 2-1 2l1 2h-2l1 1-5 1c0 2-5 0-2 3-3-1-3 2-5 2h-6l-4-1-1-2h-2l-4-2c-2-1-1 1-2 1h-3-3v2c-6 4-6-1-11 0-2-3-6-2-8-5l-5-7-6-7c-2 0-1-2-3-3l-3-2c-5-4-3-6 0-12l10-23c-4-1 3-8 0-10 2-1 4-4 3-6 3 0 2-3 3-4l4-4c3-1 4-4 7-3 3 0 4-3 7-4l2 2c2-1 3 0 3 2 1 2 2-3 3-4l5-5h5c-1 0-2-1-1-2 2-1-1-3-1-4s2-3 4-2c0-2 2-4 3-3 1-2 4-3 6-2-1-4 1-1 3 0 2 0 4-2 3 1 4-1 2-5 6-2 2 1 4-4 4-4z"/>

const gauteng = props => <path {...props} d="M316 86c2-1 3-1 4 1s-3 1-3 2v11c1 5 9-5 10-2 1 2-1 1-2 3l-1 5c-3-1-2 7-5 4-2 0-4-2-4 2-2-1-3 0-3 2s3 2 4 3 0 3 2 3l2-1c1 0 3 2 2 3-3 1-2 3-5 3-2 0-3 2-4 3-1 0-5 2-3 4-3 1-2 2-3 4l-3-1-1 1-3-6c-2-1-6 1-6 2h-6v-5l2-4c-3 1-3-1-4-1l-7 3v-3c-1-1 0-1 1-2l2-2-1-3h2l2-6c1-4 2-3 5-5 0 1 1 3 3 1v2c4-2 8-2 5-8 6 1 2-7 5-6-1 2 6 1 5-3h1c1 2 4-1 4-1 2-1 3 1 4-1l-1-2z"/>

const limpopo = props => <path {...props} d="M343 1c7-1 12 6 19 5l9-1h5l6 1c2 1 3 0 4 3l2 6c1 5 4 10 4 15 1 6-1 7 3 12l1 4c1 3 2 4 4 5l-2 1-2 2h-1c-1-2-1 0-2 0l-4-1-5 2h6c1 2-1 3-1 5l-2 4 1 2h2c-2 2 1 3 2 3 2 1 1 3-1 2 0 3-3 2-5 2 1 3 1 5 4 4l-1 2 1 2c-2 0-9 2-10 0l-1-3-4-3 1-3-3-3s2-4-1-3c-2 1-2 3-4 1l-2 4c-1-2-3-2-5-3 0 2-2 2-3 1v1l-4-1 2 5-4 3-5 5-1 1 1 2-2 2-4-1-4-5h-2v-4l-3-1c-2 1 0 1-1 2l-3 3c-2-1-1-4-4-4v3h-6c-2 1-3 1-3 3l-3 1-3 2c0 3 2 1 3 1l3-1 2 2c0 2-2 1-3 1s-4 3-5 1h-1c-2-2-2-4-5-3l-1-1c0-4 1 0 2-1s3-1 1-3c-3-1-6-2-8-1l-2-2v2h-5l-2 3c-1 1-1-1-1-1h-4c-1 0-1-2-3-2l-4-3-1-4c0-2-2 1-1 1l-6 2-6-1c-3 0-4-2-4-5 3-1 3-4 5-6l7-5c0-3 4-9 2-11l2-6 1 1 1-2 2-1v-2h2c-1-1 2-1 3-3h1c1 1 2 0 3-1 2 0 2-2 3-3 0-1 5 0 4-3 2-1 5-3 4-5 3-1 3-3 4-4 1-2 3-5 6-6l7-2 7-3c1 0 1 1 2-1l1-3c1-2 4-3 6-3l7-1h5z"/>

const mpumalanga = props => <path {...props} d="M401 52l1 7 2 7 1 16-2 13c0 1 2 3 1 5-1 3-2 4-1 8 1 2-3 1-4 0l-9-6c-4-2-8 5-9 8-2 4-7 9-8 13 0 2-1 15 2 10 0 2 2 2 2 3v3l8 7-2 3c-1 1-2-1-2-1-2 0-5 2-5 0h-4c-1 0-2-2-3-1l-3 1h-3l-1-2c-2 0-3 2-5 3l-6 1h-3c-1 0 0 2-2 2-3-1-3 3-6-1-1-2-2-7-5-5l-3-3h-3l-2-4c-2-1-4 1-5 1-1-1-5-3-6-2h1v1l-3 1v-1c-1-1-2-2-3-1 0-2-2-2-4-2 1-2 0-3 3-4-2-2 1-4 3-4l3-2 3-1 2-3c2-1 0-3-2-2-1 0-3 0-3-2s-2 0-3-1v-3c-3-2 0-2 2-2 0-4 2-2 4-2 3 3 2-5 5-4l1-4c-2-2 3-2 2-3-1-5-6 5-10 2-2-2 0-8 0-11 0-2 3 0 3-3 1-3-3-1-4-1l-6 2c-3-1 2-4 3-4 3-1 2-2 5-4h6v-3c2 0 3 1 4 3 0 2 3-2 3-2 2 0-1-3 3-2 2 1 2 4 1 5h2c1 2 1 3 4 5l4 1 2-2-1-3 5-4 5-4-2-5 4 1v-1c1 1 3 1 3-1 2 1 4 1 5 3l2-4c2 2 2 0 3 0h2c2 2 0 1 0 2 1 2 3 1 3 2l-1 4c1 1 3 0 3 2l2 4c1 2 8 0 10 0l-1-2 1-2c-3 1-3-1-4-4 2 0 5 1 5-2 2 1 3-1 1-2-1 0-4-1-2-3h-2l-1-2 2-4c0-2 2-3 1-5h-6l5-1h4c1 0 1-2 2 0h2c1-1 1-3 4-2z"/>;

const kwazuluNatal = props => <path {...props} d="M407 133c1 2 5 2 7 2l13-1-1 3 1-1-2 8c-1 3-4 7-4 10 1 3-1 6-2 9l-1 9-3 8-4 6-7 8c-4 1-6 2-9 5l-11 13c-4 5-4 11-8 15s-5 11-8 16c-3 4-7 13-11 16l-3-6-4-3c-2 0-3-3-5-2l-8-3c-2 2-7 1-9-1 0 1-2-4-3-2 0 2-2 3-3 1l-2-1v-3c0-1-1-5 3-3l3-4c4-2 3-2 1-5l3-2v-5c1-4 3-3 4-6-2-2 3-4 4-6l-3-8-3-1-3-2-3-3c-1 1-2-3-4-4 1-6 6-5 9-7l1-2 3-2 3-2c1-2 2-1 3-2 3-2 2-4 1-6l2-6v-6c3-2 1-2 0-5h2c2 0 2-4 4-3h6l5-2c1-1 4 0 5 1 2 0 3-3 5-1l5 1h4c1 0 1 2 2 1l3-3c6 2 11 2 18 2 2 0-3-18 4-15z"/>

const northernCape = props => <path {...props} d="M95 77c2 2 5 2 7 5l4 7v1l3 4h1c-3 2 2 3-1 5l3 6 1-1c1 6 2 9-1 15-2 3-5 4-3 8v5l1 4c5-5 9-1 15-2h12l2-2-1-2 4-1c3 0 3-1 5-3l4-6 3-2 2-2h3c1-1 0-2 2-3 3-1 1 8 1 10 1 6 5 1 7 5l2 1v4c-2 1 0 4 1 6-2 0-3 2-2 4l4 3 2 3h3c1 1 4 2 3 3 4 0 5 3 8 3-2 0 4-3 3-2v-1c0-2 1-2 3-1v-3c3 1 1 5 1 8 0 1 2 0 1 1v2c1 2 3 1 5 0 5-1 5 2 2 5-1 2 5 2 2 5 5 5 4-5 6-5l1-4c-2-2 1-3 2-4 0 4 4 2 7 3l-5 6c0 2 2 7 4 5 1 2-1 5-3 6 3 2-4 9 0 10l-11 25c-1 1-4 5-3 7 2 3 4 2 6 5l2 1 1 2c2 1 2 0 4 3l5 8 7 6c6 1 2 8 6 7-2 3 1 7-3 9 2 1 0 1-1 2s1 2-1 3l-3-1c-2 0-10 3-10 5l-7 1-1 5c-1 5-1 3-6 4-1 0 0 2-3 1l-1 2c-2 0-4-1-3-3-4-1-2 6-6 2 2-2-3-3-4-3l-4 1c-6-1-7 5-11 8-1-2 0-2-3-4-1 0-2 2-3 0h-3c0-2-2 0-3-1-2 0 0-4-3-4-2-1-5-5-6-2s-3 5-2 8l-3-1c0 2 2 7 0 9v-2c-3 1-1 3-3 4s-1 2-4 1c-2 0-1-2-3 0h-4v2l-2 1-3 1c-2 1-6 5-5 8l-5 1-4 3c0 2 1 3-2 5-1 0-3 2-4-1-2 3-4 1-6 1 0-4-4-4-4-7l-2-3-1-6c3 1 2-4 4-3-1-6-3-2-6 0-2 2-8 3-7 5l-2-1-2 4c0 1 2 2-1 3l-1-4-1-4c-1-1-2-2-1-3 0-2 3 0 2-3l-1-4-2-1c-2-2-4-2-3-5l-2 2-4-2c0-3 3-3 0-7v-3l1-1h-2c2-3-1-5 1-7-4 0-2-3-2-5-1-2-3-4 0-5l-2-2c2 0 3-3 2-5h-3l-2-3c0-2-1-2-2-2-2 0-2-2-3-3-2-3-2 3-2 4l-3-4c1 2 0 5-1 6-1 3-2 4-4 5-1 2-3-3-5-1s2 5-3 4c3 3-3 4-1 8l-2-1-6-13c-3-3-6-9-7-13 0-5-3-9-5-14-1-5-1-11-5-15l-1-6-4-5-2-2-1-4c-1-2-4-2-2-4l4-3c2-1 1 1 3-1 2-1 0-4 0-5s3 1 1-2h1v-1c2 1 1-2 2-3h3c3-4 4 4 5 5l3-1 2 5c0 1-4 5 1 6l-1 4 4-1h1v2h5c4 0 5 2 8 3 2 2 2 1 4 1h5l8-2 7 3c4 2 4 0 7-1-1-1-2-3 0-5h4l2-4c1-1 1-2 3-2h4c2-1 2-3 5-3V74l3 3z"/>

const westernCape = props => <path {...props} d="M185 348l-5 2c-1 0 1 2-1 2l-3-1h-6c-1 1-4-1-5-1h-9s-14 2-8 4c-1 1-8 2-5 4l-1 1-6 2s-7-2-7 0c1 2-5 0-6 0-3-1-6-2-9 0-1 1 3 3-2 2-3 0-6 0-9 2l-5 5c-1 1-6 1-4 4h-4c-2-1-5-2-7-1 0-2-6-6-9-5 2-2 3-4 0-6h2c-2-1-6 2-7-2l1-1-1 1c-2-1-6 0-6 1h-1l1-7c-2-3-7-4-10-2-2 1-1 2 0 3v5c-2-1-2-3-3-5 0-1-2-1-1-2v-3 1l-1-1 2-3 3-1c1-3-2-8-3-10l-2-1 1-2-2-3-3-3c-1-2-6-5-4-7 1 1 2 5 4 3l-2-1-1-4c-2-1-2 0-3 1l-1-3c0-1 1-2-1-4 2 0 2-4 4-4 1 1 2 5 4 2 5-4 6-11 5-17-2-6-1-11-5-17l-10-15 2 1c-2-4 4-5 1-8 3 1 3-1 3-3 0-3 5 1 5 0l4-4c1-2 2-5 1-7l3 4c0-1-1-5 2-4l2 3 3 1c1 2 1 4 4 4 2 1 2 5-1 5l2 2c-3 1-1 3 0 5 0 2-2 5 2 5-2 2 1 4-1 7h2a653 653 0 0 0-1 4c3 4 0 4 0 7l4 2 2-2c-1 3 1 3 3 5l2 1 1 4c0 2 0 2-2 3l1 3 1 3c0 2-1 4 1 5 2 0 2-1 1-3l2-4 2 1c-1-3 5-2 7-4 3-3 5-7 6-1-3-1-1 4-4 3 0 3 2 6 3 8v3l2 1 2 4c2 0 4 2 6-1 0 2 4 2 4 1l2-2v-3c1-2 0-2 3-3l6-1c-1-3 3-7 5-8l3-1h2v-3h4c2-2 1 0 3 0 3 1 2 0 4-1s0-3 3-4v2c2-2 0-7 0-9l2 1c0-2 1-5 3-7 0 0 0-3 1-2l4 3 2 1 2 1-1 2h4c1 0 1 2 3 1 0 2 2 0 3 0 3 2 2 2 3 4 4-3 5-9 11-8l4-1c1 0 6 1 4 3 3 4 2-3 6-2-1 3 2 2 3 3v1l-1 2c4-1 2 3 0 4v1h-1c1 1 1 3-1 3 0 1-5 4-6 2-1-4-4 1-4 2v1h-6v1h-2l-3 2c0 2 3 6 1 7 2 1 3 4 3 5-2-1-5-2-7 1l-1-2c2 4-1 2-2 3 0 1-2 1-1 3v3c-1 2-9 10-4 10h11l4 1c2 0 5 1 5 3s2 2 2 3c0 2-2 1-3 1l-4 4h4l1 1 3 2c-1 1-2 2-1 3h-2z"/>

const northWest = props => <path {...props} d="M258 71c-1 7 4 3 7 5 3 3 6 0 9-1v-2h1l1 5c0 1 2 1 2 3h3l2 2h4s0 2 1 1c2-1 0-3 2-4l2 1h3v-2l2 2h6c0 1 5 2 2 4-1 1-3-3-3 1l1 1c3-1 3 1 4 3 1 4-4 5-4 3h-2c1 3 1 7-3 6l1 3-1 1v2h-1v1l-4 1-1-1h-1l-1-2-3 2-2 2-2 7h-2l1 3-2 2c-1 1-2 1-1 2v3l4-2 3-1c1 1 2 2 3 1l-1 4v5h-1c0 2-2 5-3 4-4-3-2 1-6 2v-2c-3 1-7-3-6 1l-2-1-4 3c-2-1-3 1-3 3-2-1-4 1-4 2s3 3 1 4c-1 1 0 2 1 2l-5 1c-1-1-4 3-5 4-2 2-1 5-3 4v-2h-2l-3-2-4 3-3 1c-3 0-4 2-7 3-1 0 0 3-3 4-3 0-1 4-4 4-3 2-4-3-5-5 3-1 4-4 6-6-3-1-7 1-7-3-1 1-4 3-2 4l-1 4c-3 1-1 10-6 5 2-2 0-3-1-3-2-1 1-3 1-5 0-6-12 4-9-4-4-1 2-8-2-9v3l-3-1v2c2 1-2 0-3 2-1 1-6-2-8-2 1-2-2-2-4-3s-2 0-3-2l-3-3c-3 2-4-5 0-5-1-2-3-5-1-6v-4l-3-1c-1-2-6-1-6-3v-12h-1l1-3h1l1-2c-2-2 2-3-1-4l3-5v-4h2c-1-2 2-4 2-5l2-1v2c1-1 4-3 6-2h4l1 2 3 1v1l1 1v-1l4 3c0 3 5 2 5 4h2v-1h2c2 0 2 2 3 2v1h1l6 1 8 2 4-3 9 1c1 0 6-3 7-5l2-8 4-9 1-7c3-2 9-2 13-3z"/>

const list = {
  'Eastern Cape': easternCape,
  'Free State': freeState,
  'Gauteng': gauteng,
  'Limpopo': limpopo,
  'Mpumalanga': mpumalanga,
  'KwaZulu-Natal': kwazuluNatal,
  'Northern Cape': northernCape,
  'Western Cape': westernCape,
  'North West': northWest,
};


const calcStrokeColor = (active, size) => {
  if (active) {
    return '#498C0C';
  }

  if (size === 'small') {
    return 'rgb(150, 150, 150)';
  }

  return '#BEBEBE';
}


const calcProps = (active, size) => ({
  fill: active ? '#B9E293' : '#EEEEEE',
  stroke: calcStrokeColor(active, size),
  strokeWidth: 1,
})


const Province = ({ name, activeProvinces = [], size }) => {
  return createElement(
    list[name], 
    calcProps(!!activeProvinces.find(id => id === name), size)
  );
}


export default Province;


// Province.propTypes = {
//   name: t.string,
//   activeProvinces: t.arrayOf(t.string),
//   size: t.oneOf(['small', 'medium', 'large'])
// };


// Province.defaultProps = {
//   activeProvinces: [],
// }
